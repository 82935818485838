@import "../bootstrapTheme.scss";

.FormField {
  position: relative;
  label {
    position: absolute;
    background: $white;
    color: darken($input-border-color, 20%);

    transition-property: opacity, top, left, font-size, color;
    transition-timing-function: ease-in-out;
    transition-duration: 0.09s;
  }
  label {
    opacity: 0;
    z-index: -1;
    top: 17%;
    left: $input-padding-x / 2;
    left: 1.1rem;
    font-size: unset;
  }
  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    color: darken($input-border-color, 20%);
  }

  input:focus ~ label,
  select:focus ~ label,
  textarea:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  select:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label {
    opacity: 1;
    left: $input-padding-x / 1;
    padding-left: $input-padding-x / 2;
    padding-right: $input-padding-x / 2;
    top: -.7rem;
    z-index: unset;
    font-size: $small-font-size;
  }
  input:focus::placeholder,
  select:focus::placeholder,
  textarea:focus::placeholder {
    opacity: 0;
    color: transparent;
  }
}