@import "../bootstrapTheme.scss";
@import "../fields/TextField.module.scss";


.FormField {
  padding: 0 !important;
  // So that validation doesn't show
  padding-right: unset !important;
  background: unset !important;
}
.Focused {
  color: $input-focus-color;
  background-color: $input-focus-bg;
  border-color: $input-focus-border-color;
  outline: 0;
  @if $enable-shadows {
    @include box-shadow($input-box-shadow, $input-focus-box-shadow);
  } @else {
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: $input-focus-box-shadow;
  }
}

.Toolbar {
  border-bottom: $input-border-width solid $input-border-color;
  // border-bottom: none;
  border-radius: $input-border-radius $input-border-radius 0 0;
  // padding: $input-padding-y $input-padding-x;
  padding: 0;
}
.Validation {
  // background: yellow;
  padding-right: $input-height-inner;
  @each $state, $data in $form-validation-states {
        padding-right: $input-height-inner;
    // @include form-validation-state($state, map-get($data, color), map-get($data, icon));

    &:global(.is-#{$state}) {
      color: map-get($data, 'color');

      @if $enable-validation-icons {
        background-image: escape-svg(map-get($data, 'icon'));
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }

      // &:focus {
      //   border-color: $color;
      //   box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      // }
    }

  }
  
}

// Code Mirror (Chord Chart Editing)
.CodeMirror {
  border-top: none;
  border-radius: 0 0 $input-border-radius $input-border-radius;
  padding: $input-padding-y $input-padding-x;

  :global(.CodeMirror) {
    font-family: $font-family-chordchart !important; 
    font-size: 15px;
    // flex: 1 1 auto!important;
    height: 100%;
    :global(.cm-chords) { color: map-get($theme-colors, 'pop'); }
    :global(.cm-heading) { background: #EEE; color: #777; }
    :global(.cm-heading-bracket) { background: #EEE; color: #999; }
    :global(.cm-lyrics) { color: #333; }

    :global(.cm-chords-dot),
    :global(.cm-lyrics-space) { background: #EEE; color: #777; }
  }
  :global(.CodeMirror-line) {
    line-height: 1 !important;
  }
}

