/* Generated by Font Squirrel (http://www.fontsquirrel.com) on November 21, 2012 03:50:14 AM America/New_York */



@font-face {
    font-family: 'UbuntuMonoRegular';
    src: url('./UbuntuMono-R-webfont.eot');
    src: url('./UbuntuMono-R-webfont.eot?#iefix') format('embedded-opentype'),
         url('./UbuntuMono-R-webfont.woff') format('woff'),
         url('./UbuntuMono-R-webfont.ttf') format('truetype'),
         url('./UbuntuMono-R-webfont.svg#UbuntuMonoRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/*
@font-face {
    font-family: 'UbuntuMonoBold';
    src: url('UbuntuMono-B-webfont.eot');
    src: url('UbuntuMono-B-webfont.eot?#iefix') format('embedded-opentype'),
         url('UbuntuMono-B-webfont.woff') format('woff'),
         url('UbuntuMono-B-webfont.ttf') format('truetype'),
         url('UbuntuMono-B-webfont.svg#UbuntuMonoBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'UbuntuMonoItalic';
    src: url('UbuntuMono-RI-webfont.eot');
    src: url('UbuntuMono-RI-webfont.eot?#iefix') format('embedded-opentype'),
         url('UbuntuMono-RI-webfont.woff') format('woff'),
         url('UbuntuMono-RI-webfont.ttf') format('truetype'),
         url('UbuntuMono-RI-webfont.svg#UbuntuMonoItalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'UbuntuMonoBoldItalic';
    src: url('UbuntuMono-BI-webfont.eot');
    src: url('UbuntuMono-BI-webfont.eot?#iefix') format('embedded-opentype'),
         url('UbuntuMono-BI-webfont.woff') format('woff'),
         url('UbuntuMono-BI-webfont.ttf') format('truetype'),
         url('UbuntuMono-BI-webfont.svg#UbuntuMonoBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
*/

