@import "../bootstrapTheme.scss";

// .columns {
//   column-count: 1;
//   column-fill: balance;
//   column-gap: 20px;
//   &[data-columns="1"] { column-count: 1; }
//   &[data-columns="2"] { column-count: 2; }
//   &[data-columns="3"] { column-count: 3; }
//   &[data-columns="4"] { column-count: 4; }
//   &[data-columns="5"] { column-count: 5; }
// }

// .verse {overflow: hidden;}


.ChordChart {
  // line-height:1 !important;
  font-size: 1em;
  line-height: 1em;
}
.chords, .lyrics, .heading {
  font-size: 1em;
  line-height: 1em;
  margin: 0;
  padding: 0;
  font-family: $font-family-chordchart;
  white-space: pre;
}
.chords {
  color: map-get($theme-colors, 'pop');
}
.heading {
  color: $text-muted;
  line-height: 1.333em;
  text-decoration: underline;
  font-size: 0.75em;
  font-weight: 100;
  margin: 0;
}


.oneCol {
  // .col {
  //   width: 47.5%;
  //   float: left;
  // }
  // .col1 {
  //   margin-right: 5%;
  // }
}
.twoCol {
  // .col {
  //   width: 47.5%;
  //   float: left;
  // }
  // .col1 {
  //   margin-right: 5%;
  // }
}