// Override default variables before the import

$songlib-blue: #67a8bd;

$theme-colors: (
  "primary": darken(#6aaabe, 12%),
  "secondary":  #6c757d,
  "success":    #198754,
  "info":       #0dcaf0,
  "warning":    #ffc107,
  "danger":     #dc3545,
  "light":      #f8f9fa,
  // "light":      #e9ecef,
  "dark":       #212529,

  "pop": #c0343c,
  "songlib": $songlib-blue
);

$link-color: #08c;

$list-group-bg: transparent;

$font-family-chordchart: UbuntuMonoRegular, Consolas, monospace, Courier, Courier New;

$enable-negative-margins: true;
$enable-responsive-font-sizes: true;
$font-size-base:              1rem !default; // 1rem // Assumes the browser default, typically `16px` 

$h1-font-size:                $font-size-base * 1.5 !default;
// $h2-font-size:                $font-size-base * 2 !default;
// $h3-font-size:                $font-size-base * 1.75 !default;
// $h4-font-size:                $font-size-base * 1.5 !default;
// $h5-font-size:                $font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;

// $headings-margin-bottom:      $spacer / 2 !default;
// $headings-font-family:        null !default;
// $headings-font-style:         null !default;
$headings-font-weight:        400 !default;
// $headings-line-height:        1.2 !default;
// $headings-color:              null !default;

// $font-size-base: 0.875rem !default; // Assumes the browser default, typically `16px`

// $input-btn-padding-x-sm: 0.75rem;
// $input-btn-padding-x: 1rem;
// $input-btn-padding-x-lg: 1.5rem;

// $btn-border-radius-sm: 2rem;
// $btn-border-radius: 2rem;
// $btn-border-radius-lg: 2rem;

// $input-line-height: 2.0;

$navbar-padding-y: 0;

// Allow people to still read chords when offcanvas is open
$offcanvas-backdrop-opacity: 0.2;

// Import other things from bootstrap core
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";


$list-group-hover-bg: $gray-200;

// Form validation
$form-feedback-validating-color: $secondary;
$form-feedback-icon-validating: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='#{$form-feedback-validating-color}' d='M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z'/></svg>");
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  ),
  "validating": (
    "color": $form-feedback-validating-color,
    "icon": $form-feedback-icon-validating
  )
);
