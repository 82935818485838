@import "../bootstrapTheme.scss";

// $w1: 510px;
// $w2: 768px;
// $w3: 1024px;
// $w4: 1500px;
// $w5: 1920px;
// $w6: 2200px;
// $w7: 2600px;


.columns {
    column-fill: balance;
    column-gap: 20px;
    margin-bottom: 44px;

    // column-count: 4;
    column-width: 240px;
    // @media (min-width: $w1) and (max-width: $w2 - 1px) { column-count: 2; }
    // @media (min-width: $w2) and (max-width: $w3 - 1px) { column-count: 3; }
    // @media (min-width: $w3) and (max-width: $w4 - 1px) { column-count: 4; }
    // @media (min-width: $w4) and (max-width: $w5 - 1px) { column-count: 5; }
    // @media (min-width: $w5) and (max-width: $w6 - 1px) { column-count: 6; }
    // @media (min-width: $w6) and (max-width: $w7 - 1px) { column-count: 7; }
    // @media (min-width: $w7) { column-count: 8; }
    h4 {
      margin: 0;
    }
    a {
      line-height: 1.5em;
      height: 1.5em;
      display: block;
      overflow-x: hidden;
      text-decoration: none;
    }

}
.letterGroup {
    break-inside: avoid;
    margin-bottom: map-get($spacers, 3)
}