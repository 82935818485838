@import "../bootstrapTheme.scss";

.H1 {
  line-height: 1.1; 
  small { 
    font-size: 80%; 
  }
  @include media-breakpoint-only(xs) { font-size: 16px; font-weight: bold; }
  @include media-breakpoint-only(sm) { font-size: 18px; }
  @include media-breakpoint-only(md) { font-size: 20px; }

}