@import "./ubuntu-mono-fontfacekit/stylesheet.scss";

@import "./bootstrapTheme";


// Import Bootstrap and its default variables
@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// // Configuration
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/maps";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack




.btn-plain {
  @include button-variant(
    $background: transparent, 
    $border: transparent, 
    $hover-background: $gray-200, 
    $hover-border: $gray-200, 
    $active-background: $gray-300, 
    $active-border: $gray-300);
  color: $gray-600;
  .btn-group &:hover {
    background: transparent;
    border-color: transparent;
  }
  &.disabled, 
  &:disabled {
    color: $gray-500;
  }
}
.btn-group:hover {
  background: $gray-200;
  border-radius: $border-radius;
}

.btn-link {
  border-radius: $border-radius;
}

html, body {
  height: 100%;
}
#app-root {
  height: 100%;
}

.navbar {
  background: lighten(#cde, 7%) !important;
  border-bottom: 1px solid lighten(#cde, 3%) !important;
}
#navbarOffCanvasLeft .offcanvas-header {
  background: lighten(#cde, 7%) !important;
}
#navbarOffCanvasLeftUser {
  background: lighten(#cde, 7%) !important;
  border-bottom: 1px solid lighten(#cde, 3%) !important;
}

.dropdown-menu-iconed {
  .dropdown-item, .dropdown-header {
    padding-left: $dropdown-item-padding-x * 1.75;
    svg { 
      width: $dropdown-item-padding-x * 1.75; 
      margin-left: 0 - $dropdown-item-padding-x * 1.75
    }
  }
  .dropdown-item-texticon {
    width: $dropdown-item-padding-x * 1.75; 
    margin-left: 0 - $dropdown-item-padding-x * 1.75;
    display: inline-block;
    text-align: center;
  }
}


.mt-navbar {
  @media(max-width: map-get($grid-breakpoints, 'lg') - 1px) {
    margin-top: 44px;
  }
}

.navbar-twoline .btn { color: white; }

.navbar-clear {
  color: $body-color;
  height: auto !important;
  align-items: baseline !important;
  .navbar-twoline .btn {
    color: $body-color;
    line-height: 1.3 !important;
    > div { font-size: $h1-font-size; }
    > div > div { height: auto !important; }
  }
  > .navbar-nav:first-child {
    align-self: center;
    // height: 100%;
    // a { height: 100%; }
  }
}


.form-search-input {
  // &:before { content: "\f002"; }
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJzZWFyY2giIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1zZWFyY2ggZmEtdy0xNiIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSJjdXJyZW50Q29sb3IiIGQ9Ik01MDguNSA0NjguOUwzODcuMSAzNDcuNWMtMi4zLTIuMy01LjMtMy41LTguNS0zLjVoLTEzLjJjMzEuNS0zNi41IDUwLjYtODQgNTAuNi0xMzZDNDE2IDkzLjEgMzIyLjkgMCAyMDggMFMwIDkzLjEgMCAyMDhzOTMuMSAyMDggMjA4IDIwOGM1MiAwIDk5LjUtMTkuMSAxMzYtNTAuNnYxMy4yYzAgMy4yIDEuMyA2LjIgMy41IDguNWwxMjEuNCAxMjEuNGM0LjcgNC43IDEyLjMgNC43IDE3IDBsMjIuNi0yMi42YzQuNy00LjcgNC43LTEyLjMgMC0xN3pNMjA4IDM2OGMtODguNCAwLTE2MC03MS42LTE2MC0xNjBTMTE5LjYgNDggMjA4IDQ4czE2MCA3MS42IDE2MCAxNjAtNzEuNiAxNjAtMTYwIDE2MHoiPjwvcGF0aD48L3N2Zz4=);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: map-get($spacers, 3) center;

}

#sidebar-divider {
  $hidden-color: $info;
  $visible-color: $border-color;
  
  $thickness: 3px;
  position: relative;
  $border: $thickness solid $visible-color !important;
  border-right: $border;
  .btn {
    background: darken($visible-color, 20%);
    position: absolute;
    right: 0px;
    top: 0;
    z-index: 10;
    transform: translate(50%, calc(50vh - 50%));
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    border: none;
    transition: padding-left 0.2s, padding-right 0.2s, background-color 0.2s !important;

    &:hover {
      background: darken($visible-color, 40%);
    }
  }

  &.sidebar-expanded {
    border-color: $hidden-color !important;
  }
  &.sidebar-expanded .btn {
    transform: translate(calc(100% - 3px), calc(50vh - 50%));
    background: $hidden-color;
    padding-left: 4px !important;
    padding-right: 2px !important;
    &:hover {
      padding-right: 10px !important;
      padding-left: 14px !important;
      background: darken($hidden-color, 10%);
    }
  }
}

// #main-content-with-sidebar {
//   #main-content-header {
//     padding-left: 30px;
//   }
// }

// Code Mirror (Chord Chart Editing)
// .CodeMirror {

// }
// .cm-s-chordchart { font-family: $font-family-chordchart !important; font-size: 15px; }
// .cm-s-chordchart .cm-chords {color: map-get($theme-colors, 'pop');}
// .cm-s-chordchart .cm-heading {background: #EEE; color: #777;}
// .cm-s-chordchart .cm-heading-bracket {background: #EEE; color: #999; }
// .cm-s-chordchart .cm-lyrics {color: #333; }

// .cm-s-chordchart .cm-chords-dot,
// .cm-s-chordchart .cm-lyrics-space { background: #EEE; color: #777; }
