@import "../bootstrapTheme.scss";


// :global(.is-safari-touch:fullscreen),
// :global(.is-safari-touch:-webkit-full-screen) {
  // .mainContentHeader 
  // .saf .mainContentHeader
  // {
  //   background: yellow;
  //   border: 5px solid #cde;
  // }
// }

.safariTouchFullScreenHeader {

  // background: yellow;
  // border: 5px solid #cde;

  margin-top: 17px;
  margin-bottom: 15px;
  margin-left: 90px
}

.flushHeaderTxt {
  line-height: 50px !important;
  text-decoration: none !important;
  height: 50px;
}

.flushHeaderBtn {
  border-radius: 0;
  line-height: inherit !important;
  text-decoration: none !important;
  height: 50px;
  &:hover {
    background: $gray-200 !important;
  }
  &:global(.active) {
    background: $gray-300 !important;
  }
}