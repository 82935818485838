@import "./bootstrapTheme.scss";

$size: 28px;

.LibraryLi {
  padding-top: map-get($spacers, 2);
  padding-bottom: map-get($spacers, 2);
  line-height: $size;
  display: flex;

  img, .Img { 
    width: $size; 
    height: $size; 
    margin-right: map-get($spacers, 3);
  }
  .Img {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
  // svg {
  //   // mix-blend-mode: difference;
  //   color: #fff;
  // }
}